import Card from '@lyra/core/components/Card'
import DataWithLabel from '@lyra/core/components/DataWithLabel'
import formatTruncatedNumber from '@lyra/core/utils/formatTruncatedNumber'
import formatTruncatedUSD from '@lyra/core/utils/formatTruncatedUSD'
import useStats from '@lyra/web/hooks/useStats'
import useTvl from '@lyra/web/hooks/useTvl'
import { StackProps, XStack } from 'tamagui'

const TOTAL_V1_NOTIONAL_VOLUME = 1_400_000_000
const TOTAL_V1_TRADES = 24_532

type Props = StackProps

export const LandingPageStats = ({ ...stackProps }: Props) => {
  const { data, isLoading } = useStats()
  const { data: tvlData, isLoading: isTvlLoading } = useTvl()

  return (
    <XStack gap="$2" {...stackProps}>
      <Card flexGrow={1} flexShrink={1} flexBasis={0} maxWidth={140}>
        <DataWithLabel
          label="Volume"
          isEditorial
          value={
            !isLoading && data
              ? formatTruncatedUSD(TOTAL_V1_NOTIONAL_VOLUME + data.volumeTotal, { dps: 1 })
              : '...'
          }
        />
      </Card>
      <Card flexGrow={1} flexShrink={1} flexBasis={0} maxWidth={140}>
        <DataWithLabel
          label="TVL"
          isEditorial
          value={!isTvlLoading && tvlData ? formatTruncatedUSD(tvlData.tvl, { dps: 1 }) : '...'}
        />
      </Card>
      <Card flexGrow={1} flexShrink={1} flexBasis={0} maxWidth={140}>
        <DataWithLabel
          label="Trades"
          isEditorial
          value={
            !isLoading && data ? formatTruncatedNumber(TOTAL_V1_TRADES + data.tradesTotal) : '...'
          }
        />
      </Card>
    </XStack>
  )
}

export default LandingPageStats
