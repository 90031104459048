import useHover from '@lyra/core/hooks/useHover'
import { Minus, Plus } from '@tamagui/lucide-icons'
import React, { useCallback, useEffect, useState } from 'react'
import { useRef } from 'react'
import { Stack, StackProps, XStack, YStack } from 'tamagui'

import Heading from '../Heading'
import Icon from '../Icon'

type Props = {
  children: React.ReactNode
  isExpanded?: boolean
  onChangeExpanded?: (_isExpanded: boolean) => void
  title: React.ReactNode
} & StackProps

const Collapsible = ({
  children,
  title,
  isExpanded: isExpandedInput = false,
  onChangeExpanded,
  ...stackProps
}: Props) => {
  const ref = useRef<HTMLElement>(null)
  const { isHovering, handleHoverIn, handleHoverOut } = useHover()

  const [isExpanded, setIsExpanded] = useState(isExpandedInput)

  const handleChangeExpanded = useCallback(
    (isExpanded: boolean) => {
      if (onChangeExpanded) {
        onChangeExpanded(isExpanded)
      }
      setIsExpanded(isExpanded)
    },
    [onChangeExpanded]
  )

  useEffect(() => {
    setIsExpanded(isExpandedInput)
  }, [isExpandedInput])

  useEffect(() => {
    if (!ref.current) {
      return
    }
    if (!isExpanded) {
      ref.current.style.height = '0px'
    } else {
      ref.current.style.height = ref.current.scrollHeight + 'px'
    }
  }, [isExpanded])

  const isInitialExpanded = useRef(isExpandedInput).current

  return (
    <YStack {...stackProps} borderColor="$hairline" borderWidth="1px" overflow="hidden">
      <XStack
        onHoverIn={handleHoverIn}
        onHoverOut={handleHoverOut}
        onPress={() => handleChangeExpanded(!isExpanded)}
        width="100%"
        paddingHorizontal="$3"
        paddingVertical="$3"
        cursor="pointer"
        backgroundColor={isHovering ? '$hoverBg' : undefined}
      >
        <Heading>{title}</Heading>
        <Stack marginLeft="auto">
          <Icon size={20} icon={isExpanded ? <Minus /> : <Plus />} />
        </Stack>
      </XStack>
      <Stack height={isInitialExpanded ? 'fit-content' : 0} ref={ref} animation="collapsible">
        <YStack
          borderTopWidth={1}
          borderColor="$hairline"
          paddingHorizontal="$3"
          paddingVertical="$3"
        >
          {children}
        </YStack>
      </Stack>
    </YStack>
  )
}

export default Collapsible
