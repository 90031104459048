import tryRequest, { RequestOptions } from './tryRequest'
import {
  PublicStatisticsParamsSchema,
  PublicStatisticsResponseSchema,
} from './types/public.statistics'

export default async function fetchStatistics(
  params: PublicStatisticsParamsSchema,
  options?: Omit<RequestOptions, 'useGet'>
): Promise<PublicStatisticsResponseSchema> {
  return tryRequest<PublicStatisticsParamsSchema, PublicStatisticsResponseSchema>(
    '/public/statistics',
    params,
    {
      ...options,
      useGet: true, // NOTE: orderbook wants us to use GET for this route
    }
  )
}
