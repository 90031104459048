import BodyText from '@lyra/core/components/BodyText'
import Collapsible from '@lyra/core/components/Collapsible'
import Heading from '@lyra/core/components/Heading'
import Link from '@lyra/core/components/Link'
import { LANDING_PAGE_WIDTH } from '@lyra/web/constants/layout'
import { PageId } from '@lyra/web/constants/pages'
import { DOCS_URL, HELP_URL } from '@lyra/web/constants/urls'
import { getPagePath } from '@lyra/web/utils/pages'
import { useState } from 'react'
import { YStack } from 'tamagui'

import NextLink from '../common/NextLink'

export const LandingFaqSection = () => {
  const [expandedFaq, setExpandedFaq] = useState<number | null>(null)

  return (
    <YStack
      borderTopColor="$hairline"
      borderTopWidth={1}
      paddingVertical="$10"
      width="100%"
      alignItems="center"
      paddingHorizontal="$3"
    >
      <YStack
        width="100%"
        $desktop={{
          // shorter than regular widths
          maxWidth: LANDING_PAGE_WIDTH,
        }}
        gap="$6"
      >
        <YStack gap="$1">
          <Heading size="h2">FAQs</Heading>
          <BodyText size="lg" color="secondary">
            Frequently asked questions from the Derive community.{' '}
            <Link href={HELP_URL} target="_blank" label="Learn more" />
          </BodyText>
        </YStack>
        <YStack gap="$3">
          <Collapsible
            title="What is a DEX?"
            isExpanded={expandedFaq === 1}
            onChangeExpanded={(_isOpen) => setExpandedFaq(_isOpen ? 1 : null)}
          >
            <BodyText size="lg" color="secondary">
              {`A DEX, or decentralized exchange, is a peer-to-peer marketplace where transactions occur directly between traders. DEXs foster financial transactions that aren't officiated by banks, brokers, or any other intermediary. Their key features include self-custody, transparency, borderless transactions and immutability. As a user of a DEX, you control your private key and no centralized entity can access your funds or make trades on your behalf.`}
            </BodyText>
          </Collapsible>
          <Collapsible
            title="What is the Derive Protocol?"
            isExpanded={expandedFaq === 4}
            onChangeExpanded={(_isOpen) => setExpandedFaq(_isOpen ? 4 : null)}
          >
            <BodyText size="lg" color="secondary">
              The Derive Protocol is a permissionless protocol that settles perpetuals and options
              trades. The protocol is deployed on an Optimistic Rollup that settles to the Ethereum
              blockchain. The protocol is governed by the Derive DAO and DRV token holders.&nbsp;
              <Link label="Learn more" href={DOCS_URL} target="_blank" />
            </BodyText>
          </Collapsible>
          <Collapsible
            title="Can US citizens use the Derive Exchange?"
            isExpanded={expandedFaq === 5}
            onChangeExpanded={(_isOpen) => setExpandedFaq(_isOpen ? 5 : null)}
          >
            <BodyText size="lg" color="secondary">
              No, Derive Exchange and its products are not available to customers in the United
              States.
              <br />
              <br />
              Our platform is unavailable in the following regions: Australia, the United States of
              America, Canada, Panama, Iran, Iraq, Libya, Mali, Nicaragua, Cuba, Democratic People’s
              Republic of Korea (North Korea), Russia, Somalia, Sudan, Syria, Yemen, Zimbabwe,
              Myanmar (Burma), Cote D’Ivoire (Ivory Coast), Democratic Republic of Congo, the
              regions of Crimea, Donetsk, or Luhansk
              <br />
              <br />
              Read our &nbsp;
              <NextLink label="Terms of Use" href={getPagePath({ page: PageId.TermsOfUse })} />
              &nbsp;for more details.
            </BodyText>
          </Collapsible>
        </YStack>
      </YStack>
    </YStack>
  )
}

export default LandingFaqSection
