'use client'

import LandingBlogSection from '@lyra/web/components/landing/LandingBlogSection'
import LandingFaqSection from '@lyra/web/components/landing/LandingFaqSection'
import LandingHeroSection from '@lyra/web/components/landing/LandingHeroSection'
import LandingPageFooter from '@lyra/web/components/landing/LandingPageFooter'
import { GhostPost } from '@lyra/web/constants/ghost'
import React from 'react'

type Props = {
  blogPosts: GhostPost[]
}

const LandingPageHelper = ({ blogPosts }: Props) => {
  return (
    <>
      <LandingHeroSection />
      <LandingFaqSection />
      {blogPosts.length > 0 ? <LandingBlogSection blogPosts={blogPosts} /> : null}
      <LandingPageFooter />
    </>
  )
}

export default LandingPageHelper
