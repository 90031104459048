import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Circle, Stack, XStack, YStack, YStackProps } from 'tamagui'

const Carousel = ({ slides, ...props }: { slides: React.ReactNode[] } & YStackProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const containerRef = useRef<HTMLDivElement | null>(null)
  const slideRefs = useRef<Array<HTMLDivElement | null>>([])

  const handleScroll = useCallback(() => {
    if (!slideRefs.current) {
      return
    }
    let closest = 0
    let closestX: null | number = null
    slideRefs.current.forEach((element, index) => {
      const boundingRect = element?.getBoundingClientRect()
      if (!boundingRect || !containerRef.current) {
        return
      }
      const midX = boundingRect.x + boundingRect.width / 2
      const containerMidX = containerRef.current.clientWidth / 2
      const distanceToMidX = Math.abs(containerMidX - midX)
      if (!closestX) {
        closest = index
        closestX = distanceToMidX
      }
      if (distanceToMidX < closestX) {
        closest = index
        setSelectedIndex(closest)
        closestX = distanceToMidX
      }
      if (closest === 0) {
        setSelectedIndex(0)
      }
    })
  }, [])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll)
    }
    const container = containerRef?.current
    return () => {
      container?.removeEventListener('scroll', handleScroll)
    }
  }, [handleScroll])

  return (
    <YStack {...props}>
      <Stack
        style={{ position: 'relative', overflowY: 'hidden', scrollSnapType: 'x mandatory' }}
        ref={containerRef}
        // Bottom padding for scroll bar
        paddingBottom="$1"
      >
        <XStack space="$3">
          {slides.map((slide, index) => (
            <div
              key={index}
              ref={(ref) => (slideRefs.current[index] = ref)}
              style={{ scrollSnapAlign: 'center', height: '100%' }}
            >
              {slide}
            </div>
          ))}
        </XStack>
      </Stack>

      <XStack space="$2" width={'100%'} justifyContent="center" paddingTop="$3">
        {slides.map((_, index) => {
          return (
            <Circle
              key={index}
              size={9}
              backgroundColor={index == selectedIndex ? '$invertedBg' : '$hairline'}
            />
          )
        })}
      </XStack>
    </YStack>
  )
}

export default Carousel
